export const contactBookGroups = [
    { groupId: 10001, partyId: "1001", groupName: "Football Team" },
    { groupId: 10002, partyId: "1002", groupName: "Fakir" },
    { groupId: 10003, partyId: "1003", groupName: "Developer" },
];

export const contactBookContacts = [
    { contactId: 101, groupId: "10001", partyId: "1001", contactName: "Shabbir Ahmed", contactNumber: "01717590703" },
    { contactId: 102, groupId: "10002", partyId: "1002", contactName: "Mustafa Rahman", contactNumber: "0182947393" },
    { contactId: 103, groupId: "10003", partyId: "1003", contactName: "Grad Mon", contactNumber: "03947264837" },
    { contactId: 104, groupId: "10001", partyId: "1001", contactName: "Yent Sie", contactNumber: "01836982733" },
    { contactId: 105, groupId: "10002", partyId: "1002", contactName: "Gery Nikol", contactNumber: "04833973937" },
    { contactId: 106, groupId: "10003", partyId: "1003", contactName: "Fez humer", contactNumber: "04826382424" },
    { contactId: 107, groupId: "10001", partyId: "1001", contactName: "Nub garet", contactNumber: "90183783782" },
];
