import axios from "axios";
import { OFBIZ_EP,CONTACT_BOOK_EP } from "../config";
import { XAuth } from "./XAuth";


export const SmsTask = {
    sendSms: (ctx, { data }) => axios
        .post(
            `${OFBIZ_EP}/SmsTask/sendSMS`,
            { ...data },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${XAuth.token()}`,
                }
            }
        )
        .then(response => {
            const { data } = response;
            // console.log(data)

            if (data.report) {
                return Promise.resolve(data);
            } else {
                return Promise.reject({ message: data.errorMessage });
            }
        })
        .catch(error => {
            const response = error.response || { data: { error: error.message } };
            const { status: code, statusText: text, data } = response;
            return Promise.reject({ code, message: data.error || text });
        }),


    sendSmsFromExcel: (formData) => {
        return axios
            .post(
                `${CONTACT_BOOK_EP}/sendSmsFromExcel`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${XAuth.token()}`,
                    },
                    responseType: 'blob', // Make sure the response type is set to 'blob' for binary data
                }
            )
            .then(response => {
                console.log("Response from API:", response);

                const { data } = response;
                if (data instanceof Blob) {
                    const file = new Blob([data], { type: 'application/octet-stream' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(file);
                    link.download = 'sms_report.xlsx';
                    link.click();
                    return Promise.resolve(data);
                } else {
                    console.log("API response message:", data);
                    return Promise.resolve(data);
                }
            })
            .catch(error => {
                console.log("Error during API call:", error);
                const response = error.response || { data: { error: error.message } };
                const { status: code, statusText: text, data } = response;
                return Promise.reject({ code, message: data.error || text });
            });
    },


    lastTaskReports: () => {
        return [];
    },
    lastTaskErrors: () => {
        return [];
    }
};
