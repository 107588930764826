
import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    Button,
    Table,
    Space,
    Pagination,
    Card,
    Select,
    Row,
    Col,
    Modal, Typography, DatePicker, notification, Tooltip, Upload, message, Checkbox, TimePicker, Descriptions, Tag
} from "antd";
import Title from "antd/es/typography/Title";
import {Br} from "./Br";

import {CampaignService} from "../services/CampaignService";
import {
    ExclamationCircleOutlined,

    FileTextTwoTone,
    PlusCircleFilled
} from "@ant-design/icons";
import * as sheetjs from "xlsx";
import {SenderIdService} from "../services/SenderIdService";
import {unflatten} from "../Util";
import moment from 'moment';
import {PartyIdCatcher} from "./HomeNew";
import {InputSms} from "./InputSms";
import {SmsTask} from "../services/SmsTask";




const SchedulePickerWithType = ({type}) => {
    if (type === 'default') return (<>
        <Row>
            <Col md={12}>
                <Form.Item name="schedule.props.scheduleStart" initialValue={moment(new Date())}>
                    <DatePicker placeholder="Date" showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss"/>
                </Form.Item>
            </Col>
        </Row>
    </>);
    if (type === 'DateRange') return (<>
        <Row>
            <Col md={12}>
                <Form.Item name="schedule.props.scheduleStart" initialValue={moment(new Date())}>
                    <DatePicker placeholder="Start Date" showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss"/>
                </Form.Item>
            </Col>
            <Col md={12}>
                <Form.Item name="schedule.props.scheduleEnd" initialValue={moment(new Date())}>
                    <DatePicker placeholder="End Date" showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss"/>
                </Form.Item>
            </Col>
        </Row>
    </>);
    if (type === 'DateRangeAndActiveHours') return (<>
        <Row>
            <Descriptions title="Date">
                <Descriptions.Item label="Start-Date" span={1} labelStyle={{ alignItems:'start'}}>
                    <Form.Item name="schedule.props.scheduleStart" initialValue={moment(new Date())}>
                        <DatePicker placeholder="Start Date" showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss"/>
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="End-Date" span={1} labelStyle={{ alignItems:'start'}}>
                    <Form.Item name="schedule.props.scheduleEnd" initialValue={moment(new Date())}>
                        <DatePicker placeholder="End Date" showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss"/>
                    </Form.Item>
                </Descriptions.Item>
            </Descriptions>
        </Row>
        <Row>
            <Descriptions title="Active Hours" Layout="vertical" >
                <Descriptions.Item label="Start at" span={1} labelStyle={{ alignItems:'start'}}>
                    <Form.Item name="schedule.props.activeHourStart" initialValue={moment(new Date())}>
                        <TimePicker placeholder="Start Time"/>
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="End at" span={1} labelStyle={{ alignItems:'start'}}>
                    <Form.Item name="schedule.props.activeHourEnd" initialValue={moment(new Date()).add(1, 'hours')}>
                        <TimePicker placeholder="End Time"/>
                    </Form.Item>
                </Descriptions.Item>
            </Descriptions>
        </Row>

    </>);
};

const WriteForm = ({recordArg, onRecordSaved,close }) => {
    const { Option } = Select;
    const [writeForm] = Form.useForm();

    const [isCreateForm, setIsCreateForm] = useState(true);
    const [lastWrite, setLastWrite] = useState(recordArg);

    const [senderIds, setSenderIds] = useState([]);
    const partyId = PartyIdCatcher();
    useEffect(()=> {
        SenderIdService.fetchRecords({partyId})
            .then(data=>{
                setSenderIds(data.senderIds);
            })
    },[])

    // useEffect(() => writeForm.resetFields(), [recordArg, writeForm]);
    useEffect(() => {
        setIsCreateForm(Object.keys(recordArg).length === 0);
        writeForm.resetFields();
        writeForm.setFieldsValue(recordArg);
    }, [recordArg]);

    useEffect( () => {
        if (lastWrite === recordArg) return;
        isCreateForm && writeForm.resetFields();
    },[lastWrite]);
    useEffect( () => {
        if (lastWrite === recordArg) return;
        isCreateForm && writeForm.resetFields();
    },[lastWrite]);
    const [type, setType] = useState('default');

    return (<>
        <Form
            form={writeForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 20 }}
            labelAlign={"left"}
            style={{
                padding:'35px'
            }}
            onFinish={() => writeForm.resetFields()}
        >
            <Form.Item name="campaignName" label="Campaign Name" rules={[{ required: true }]} children={<Input/>} />
            <Form.Item name="scheduleStatus" label="Schedule Status" rules={[{ required: true }]} hidden initialValue={"enabled"} children={<Input/>} />

            <Form.Item name="senderId" label="Sender ID" rules={[{ required: true }]}>
                <Select style={{ minWidth: 150 }}>
                    {senderIds.map((v, i) => <Select.Option key={v.senderId} value={v.senderId}>{v.senderId}</Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item
                name="phoneNumbers"
                label={<>
                    <span>Contacts</span>
                    <Tooltip title="Import (Excel, CSV, Text)">
                        &nbsp;&nbsp;
                        <Upload
                            maxCount={1}
                            accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
                            customRequest={r => {
                                const reader = new FileReader();

                                reader.onload = () => {
                                    const contactBook = sheetjs.read(reader.result, { sheets: 0 });
                                    const contactSheet = Object.values(contactBook.Sheets)[0];

                                    const contacts = sheetjs.utils
                                        .sheet_to_json(contactSheet, { skipHidden: true })
                                        .reduce((acc, v) => {
                                            if (v.msisdn !== undefined) {
                                                acc.push(v.msisdn);
                                            }
                                            return acc;
                                        }, []);

                                    contacts.length ? r.onSuccess(JSON.stringify(contacts)) : r.onError("zero_msisdn_found");
                                };

                                reader.onerror = () => {
                                    r.onError(reader.error.message);
                                }

                                reader.readAsArrayBuffer(r.file);
                            }}
                            onChange={info => {
                                if (info.file.status === 'done') {
                                    writeForm.setFieldsValue({ ...writeForm.getFieldsValue, phoneNumbers: info.file.response.join(", ") })
                                    return message.success(`Found ${info.file.response.length} MSISDN(s)`);
                                }
                                if (info.file.status === 'error') {
                                    return message.error(`Error: ${info.file.error.toUpperCase()}`);
                                }
                            }}
                            showUploadList={false}
                            children={<Button shape="round" icon={<FileTextTwoTone />} />}
                        />
                    </Tooltip>
                </>}
                rules={[{ required: true }]}
                children={<Input.TextArea />}
            />
            {<InputSms/>}

            <Form.Item name="expireAt" label="Valid Until" rules={[{ required: true }]} initialValue={moment(new Date()).add(7,"days")}>
                <DatePicker placeholder="Valid Date" showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss"/>
            </Form.Item>


            <Form.Item name="schedule.policy" id="selected" label="Schedule Policy" initialValue={type}>

                <Select onChange={setType}>
                    <Option value="default">Default (Schedule On)</Option>
                    <Option value="DateRange" disabled>Start-End Date</Option>
                    <Option value="DateRangeAndActiveHours" disabled>Start-End Date, Active-hours</Option>

                </Select>

            </Form.Item>
            <Form.Item colon={false} label=" " style={{ marginTop:'0px'}} >
                <Card>
                    <SchedulePickerWithType type={type}/>
                </Card>
            </Form.Item>



        </Form>
    </>);
};



const DataView = ({ sms, viewPage, viewLimit, onEdit, onDelete }) => {
    const [modalDataMsg, setModalDataMsg] = useState(null);

    const showModalMsg = (data) => setModalDataMsg(data);
    const handleOkMsg = () => setModalDataMsg(null);
    const handleCancelMsg = () => setModalDataMsg(null);

    const confirmDelete = (contact) =>
        Modal.confirm({
            title: "Confirm sending SMS?",
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    Sending SMS to: <strong>{contact.msisdn}</strong>
                </>
            ),
            onOk() {
                onDelete(contact);
            },
        });

    return (
        <>
            <Table
                style={{ marginLeft: "6px" }}
                size="small"
                dataSource={sms}
                rowKey={(record) => `${record.msisdn}-${record.senderId}`}
                locale={{ emptyText: sms?.length ? "No Data" : "No Contacts" }}
                pagination={{
                    current: viewPage,
                    pageSize: viewLimit,
                    total: sms.length,
                }}
            >
                {/* Index Column */}
                <Table.Column
                    dataIndex={undefined}
                    title={"#"}
                    render={(_, __, i) => (viewPage - 1) * viewLimit + i + 1}
                />

                {/* Sender ID */}
                <Table.Column title="Sender ID" dataIndex={"senderId"} />

                {/* MSISDN */}
                <Table.Column title="MSISDN" dataIndex={"msisdn"} />

                {/* Message Column with Ellipsis */}
                <Table.Column
                    title="Message"
                    dataIndex={"message"}
                    width={"150pt"}
                    render={(text) =>
                        text.length > 20 ? (
                            <>
                                <span
                                    style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100pt",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    {text}
                                </span>
                                <Button
                                    type="link"
                                    onClick={() => showModalMsg(text)}
                                >
                                    Show All
                                </Button>
                            </>
                        ) : (
                            text
                        )
                    }
                />

                {/* Actions */}
                <Table.Column
                    title="Status"
                    dataIndex={undefined}
                    render={(value, record) => (
                        <Button
                            onClick={() => confirmDelete(record)}
                            type="link"
                        >
                            Send Message
                        </Button>
                    )}
                />
            </Table>

            {/* Message Modal */}
            <Modal
                title="Message"
                visible={!!modalDataMsg}
                onOk={handleOkMsg}
                onCancel={handleCancelMsg}
            >
                {modalDataMsg}
            </Modal>
        </>
    );
};

export default DataView;


const DataPager = ({ totalPagingItems, currentPage, onPagingChange }) => {
    return (<>
        <Space align="end" direction="vertical" style={{ width: "100%" }}>
            <Pagination
                total={totalPagingItems}
                defaultPageSize={10}
                pageSizeOptions={["10", "20", "50", "100", "200"]}
                showSizeChanger={true}
                onChange={onPagingChange}
                current={currentPage}
            />
        </Space>
    </>);
};

export const SendSmsFromExcel = () => {
    const [uploadedContacts, setUploadedContacts] = useState([]);
    console.log(uploadedContacts)

    // Component States
    const [lastQuery, setLastQuery] = useState({});
    const [campaigns, setCampaigns] = useState([]);
    const [CampaignsFetchCount, setCampaignsFetchCount] = useState(0);
    const [CampaignsFetchError, setCampaignsFetchError] = useState(null);
    const [modalData, setModalData] = useState(null);

    const showModal = (data) => setModalData(data);
    const handleOk = () => setModalData(null);
    const handleCancel = () => setModalData(null);

    const reversedCampaigns = [...campaigns];
    sortByKey(reversedCampaigns, "campaignId");

    function sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }




    useEffect(() => {
        setLastQuery({ page: 1, limit: 10 });
    }, []);

    const processExcelFile = (file) => {
        const formData = new FormData();
        formData.append("file", file);  // Append the file to FormData

        // Send the file directly to the backend via the API
        SmsTask.sendSmsFromExcel(formData)
            .then((response) => {
                // Check if the response contains a report or success message
                if (response.message) {
                    // Display the success toast with the message from the response
                    notification.success({
                        key: `SMS_${Date.now()}`,
                        message: 'All SMS sent successfully!',
                        description: response.message,  // Display the message from the response
                        duration: 5, // Set the toast duration
                    });
                } else {
                    // Fallback if there is no message
                    notification.success({
                        key: `SMS_${Date.now()}`,
                        message: 'All SMS sent successfully!',
                        description: 'Your SMS task was successfully processed.',
                        duration: 5,
                    });
                }
            })
            .catch((error) => {
                notification.error({
                    key: `SMS_${Date.now()}`,
                    message: "Task Failed",
                    description: <>Error creating order.<br />{error.message}</>,
                    duration: 5,
                });
            });
    };

    return (
        <>
            <Row>
                <Col md={24} style={{ marginLeft: "5px" }}>
                    <Card
                        title={<Title level={5}>Send Sms From Excel</Title>}
                        headStyle={{ backgroundColor: "#f0f2f5", border: 0, padding: "0px" }}
                        size="small"
                        extra={
                            <Upload
                                maxCount={1}
                                accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
                                customRequest={(r) => processExcelFile(r.file)}
                                showUploadList={false}
                            >
                                <Button
                                    type="primary"
                                    shape="round"
                                    style={{ background: "#1890ff", borderColor: "#1890ff" }}
                                    icon={<FileTextTwoTone />}
                                >
                                    Upload Excel
                                </Button>
                            </Upload>
                        }
                    ></Card>
                </Col>
            </Row>
            <Modal
                width={1000}
                header="Create Campaign"
                key="createCampaign"
                visible={modalData}
                footer={null}
                maskClosable={false}
                closable={false}
                style={{ top: 20 }}
                bodyStyle={{ height: "57rem" }}
            >
                <WriteForm
                    close={handleCancel}
                    recordArg={modalData}
                    onRecordSaved={() => setLastQuery({ ...lastQuery, orderBy: "updatedOn DESC", page: 1 })}
                />
            </Modal>
            {/*<DataView*/}
            {/*    sms={uploadedContacts}*/}
            {/*    viewPage={lastQuery.page}*/}
            {/*    viewLimit={lastQuery.limit}*/}
            {/*    onEdit={showModal}*/}
            {/*/>*/}

            {/*<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>*/}
            {/*    <DataPager*/}
            {/*        totalPagingItems={CampaignsFetchCount}*/}
            {/*        currentPage={lastQuery.page}*/}
            {/*        onPagingChange={(page, limit) => setLastQuery({ ...lastQuery, page, limit })}*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    );
};